import 'styles/host-application.css'
import 'styles/text.css'
import { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import Button from 'components/button'
import yuss from 'assets/yuss.gif'

export interface ApplicationItem {
  title: string
  subtitle?: string
  placeholder: string
  value: string
  setValue: (val: string) => void
}

export default function HostApplication() {
  const [name, setName] = useState('')
  const [nickname, setNickname] = useState('')
  const [school, setSchool] = useState('')
  const [major, setMajor] = useState('')
  const [trading, setTrading] = useState('')
  const [length, setLength] = useState('')
  const [prodStrats, setProdStrats] = useState('')
  const [style, setStyle] = useState('')
  const [expertise, setExpertise] = useState('')
  const [inspiration, setInspiration] = useState('')
  const [rule, setRule] = useState('')
  const [hobbies, setHobbies] = useState('')
  const [facts, setFacts] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [isError, setError] = useState(false)
  const [message, setMessage] = useState('')

  const applicationData: ApplicationItem[] = useMemo(
    () => [
      {
        title: "What's your name?",
        subtitle: '',
        placeholder: 'Your name...',
        value: name,
        setValue: setName
      },
      {
        title: 'Do you have a nickname?',
        subtitle: '(Don’t be shy, but also, this totally optional.)',
        placeholder: 'Your nickname...',
        value: nickname,
        setValue: setNickname
      },
      {
        title: 'What school did/do you attend?',
        placeholder: 'School...',
        value: school,
        setValue: setSchool
      },
      {
        title: 'What is/was your major?',
        placeholder: 'Physics of money (how it sometimes just falls up and then back down)',
        value: major,
        setValue: setMajor
      },
      {
        title: 'How did you get into trading?',
        placeholder: 'My brother’s wife’s niece told me about some meme stocks...',
        value: trading,
        setValue: setTrading
      },
      {
        title: 'How long have you been trading for?',
        placeholder: 'Straight out of the womb...',
        value: length,
        setValue: setLength
      },
      {
        title: 'What trading products and strategies do you use?',
        placeholder: 'Bad Trader, obviously...',
        value: prodStrats,
        setValue: setProdStrats
      },
      {
        title: 'What’s your trading style?',
        subtitle: '(e.g., active, speculative, technical analysis, statistical, fuck it)',
        placeholder: 'Trading style...',
        value: style,
        setValue: setStyle
      },
      {
        title: 'What are your top trading expertises?',
        subtitle: '(e.g., statistics, options trading, portfolio management, losing money)',
        placeholder: 'I can count to 10 on all fingers...',
        value: expertise,
        setValue: setExpertise
      },
      {
        title: 'Where do you look for trading inspiration?',
        placeholder: 'The moon, mostly...',
        value: inspiration,
        setValue: setInspiration
      },
      {
        title: 'What’s your #1 rule when it comes to trading?',
        subtitle: '(e.g., No matter what info you stumble across, everyone else probably already knows about it)',
        placeholder: 'Buy high, sell low...',
        value: rule,
        setValue: setRule
      },
      {
        title: 'What are you hobbies or interests?',
        subtitle: '(e.g., traveling, hockey, Minecraft, deathcore)',
        placeholder: 'I watch birds... I’m still onto them...',
        value: hobbies,
        setValue: setHobbies
      },
      {
        title: 'What are some fun facts about yourself?',
        subtitle:
          "(e.g., Julia spent a lot of time getting lasers shot in her eyes for her Master's degree but still doesn't have laser vision.)",
        placeholder: 'I like turtles...',
        value: facts,
        setValue: setFacts
      }
    ],
    [expertise, facts, hobbies, inspiration, length, major, name, nickname, prodStrats, rule, school, style, trading]
  )

  useEffect(() => {
    setMessage(`${applicationData.map(item => `${item.title}%0D%0A%0D%0A${item.value}%0D%0A%0D%0A`)}`)
  }, [applicationData])

  const submit = () => {
    if (!isFull) {
      setError(true)
      return
    }

    setError(false)
    setSubmitted(true)
  }

  const isFull = applicationData.every(item => !_.isEmpty(item.value) || item.title === 'Do you have a nickname?')

  return (
    <div className="app-page">
      <div className="h2 app-header-text">So you think you have the chops to be a host?</div>
      <div className="h5 app-subheader-text">
        The below questionnaire will help us get a better idea of what you’re all about. Be honest - be yourself.
      </div>
      {applicationData.map((item, index) => ApplicationRow(item, index))}
      <div
        className={`h3 app-submit-container ${(isFull || submitted) && 'app-submit-full'} ${
          isError && 'app-submit-error'
        }`}
      >
        {submitted
          ? 'Fantastic. We’ll be in touch shortly!'
          : isFull
          ? 'Greenlight to proceed - Godspeed!'
          : isError
          ? 'One or more questions have not been answered. Please answer to submit.'
          : 'Please fill out the above questionnaire to submit your answers'}
        {submitted ? (
          <img src={yuss} className="yuss" alt="yuss" />
        ) : (
          <a
            href={
              !isFull
                ? null
                : `mailto:support@badtrader.app?subject=${'Host Application'}%20-%20${name}&body=${message}`
            }
            onClick={submit}
            className="app-submit-button"
          >
            <Button
              label="Submit my answers"
              buttonStyle={`button-yellow rounded-button-light rounded-button-label-light app-button ${
                isFull && 'button-green'
              }`}
            />
          </a>
        )}
      </div>
    </div>
  )
}

function ApplicationRow(item: ApplicationItem, index: number) {
  return (
    <div className="application-item">
      <div className={`h5 app-question ${!_.isEmpty(item.value) && 'question-answered'}`}>
        Question <div>{index + 1}</div>
      </div>
      <div className="app-container">
        <div className={`h3 app-title-row ${!_.isEmpty(item.value) && 'app-title-answered'}`}>{item.title}</div>
        <div className={`large-500 app-subtitle ${_.isEmpty(item.subtitle) && 'app-subtitle-inactive'}`}>
          {_.isEmpty(item.subtitle) ? '.' : item.subtitle}
        </div>
        <input
          className="app-input"
          value={item.value}
          onChange={evt => item.setValue(evt.target.value)}
          placeholder={item.placeholder}
        />
      </div>
    </div>
  )
}
