import 'styles/background.css'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { gsap } from 'gsap'
import _ from 'lodash'

export default function Background({ src, tintColor }) {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
  const backgroundRef = useRef(null)

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window
    return {
      width,
      height
    }
  }

  useEffect(() => {
    const handleMouseMove = ({ clientX, clientY }) => {
      const _x = Math.max(-400, Math.min(400, windowDimensions.width / 2 - clientX))
      const _y = Math.max(-400, Math.min(400, windowDimensions.height / 2 - clientY))
      const x = (3 * _x) / 100
      const y = (5 * _y) / 100

      if (!_.isNil(backgroundRef.current)) {
        backgroundRef.current.moveTo(x, y)
      }
    }

    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    document.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
      document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [windowDimensions.height, windowDimensions.width])

  return (
    <div className="bg-container">
      <BackgroundImage style={{ backgroundImage: `url(${src})` }} ref={backgroundRef} />
      <div className="background-tint" style={{ backgroundColor: tintColor }} />
    </div>
  )
}

const BackgroundImage = forwardRef((props: { style }, ref) => {
  const el = useRef()

  useImperativeHandle(
    ref,
    () => {
      // return our API
      return {
        moveTo(x, y) {
          gsap.to(el.current, { x, y, duration: 5, delay: 0.1, ease: 'back.out' })
        }
      }
    },
    []
  )

  return <div className="background" ref={el} style={props.style} />
})
