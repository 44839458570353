import Ably from 'ably'
import _ from 'lodash'

export enum AblyState {
  ATTACHED = 'attached',
  INITIALIZED = 'initialized',
  ATTACHING = 'attaching',
  DETACHED = 'detached'
}

export enum ConnectionState {
  INITIALIZED = 'initialized',
  CONNECTING = 'connecting',
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
  SUSPENDED = 'suspended',
  CLOSING = 'closing',
  CLOSED = 'closed',
  FAILED = 'failed'
}

// NOTE: Admin uses messageData.data, app uses messageData.data.data, this is a helper allowing both
export const getMessage = (messageData: any) => messageData?.data?.data ?? messageData?.data

export class AblyClient {
  constructor(cognito) {
    this.getTokenRequest = cognito.getTokenRequest
  }

  getTokenRequest: () => Promise<any> = null
  client: Ably.Realtime = null
  userId = null
  channelState: Map<string, string> = new Map()

  getUserEventsChannel = () => `bad_trader:${this.userId}:technical:out`

  init = () => {
    this.client = new Ably.Realtime({
      idempotentRestPublishing: true,
      authCallback: async (_tokenParams, callback) => {
        try {
          const tokenRequest = await this.getTokenRequest()
          this.userId = tokenRequest.cognitoUsername

          callback(null, tokenRequest.ablyAccessToken)
        } catch (error: any) {
          callback(error, null)
        }
      }
    })
  }

  // refresh = () => {
  //   const idToken = this.idToken

  //   this.reset()
  //   this.init(idToken)
  // }

  // connect = (callback = _.noop) => {
  //   if (_.isNil(this.client)) {
  //     return
  //   }

  //   if (this.client.connection.state === ConnectionState.CONNECTED) {
  //     callback()
  //     return
  //   }

  //   this.client.connection.on(ConnectionState.CONNECTED, callback)
  // }

  enterPresenceChannel = (channelName: string, displayName: string, status: string) => {
    const channel = this.getChannel(channelName)

    if (_.isNil(channel)) {
      return false
    }

    channel.presence.enterClient(this.userId, { displayName: displayName, status: status })

    return true
  }

  leavePresenceChannel = (channelName: string) => {
    const channel = this.getChannel(channelName)

    if (_.isNil(channel)) {
      return false
    }

    channel.presence.leaveClient(this.userId)

    return true
  }

  disconnect = (callback = _.noop) => {
    if (_.isNil(this.client)) {
      return
    }

    this.client.connection.on(ConnectionState.CLOSED, callback)
    this.client.connection.close()
  }

  subscribe = (channelName: string, callback: (data) => void) => {
    const channel = this.getChannel(channelName)

    if (_.isNil(channel)) {
      return false
    }

    channel.subscribe(callback)

    return true
  }

  getChannel = (channelName: string) => {
    if (_.isNil(this.client)) {
      return null
    }

    const channel = this.client.channels.get(channelName)

    if (!this.channelState.has(channelName)) {
      channel.on(stateChange => {
        this.channelState.set(channelName, stateChange.current)
      })
    }

    return channel
  }

  publish = (channelName: string, messageData: any) => {
    const channel = this.getChannel(channelName)

    if (_.isNil(channel)) {
      return
    }

    const message = { data: messageData }

    channel.publish(message)
  }

  reset = () => {
    this.client = null
  }
}
