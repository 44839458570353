import 'styles/loader.css'

export default function Loader({ small = false, style = {} }) {
  return (
    <div id="loader">
      <div className={`cube-folding ${small && 'cube-folding-small'}`} style={style}>
        <span className="leaf1"></span>
        <span className="leaf2"></span>
        <span className="leaf3"></span>
        <span className="leaf4"></span>
      </div>
    </div>
  )
}
