import 'styles/login-modal.css'
import closeIcon from 'assets/close.svg'
import logoGif from 'assets/logo.gif'
import eye from 'assets/eye.svg'
import error from 'assets/error.svg'
import eyeClosed from 'assets/eye-closed.svg'
import { useState } from 'react'
import Button from './button'
import _ from 'lodash'
import Loader from './loader'

export const USER_BLOCKED_MESSAGE = 'User is disabled.'

export default function LoginModal({ visible, setVisible, onLogin, cognito }) {
  const [passwordShown, setPasswordShown] = useState(false)
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [code, setCode] = useState('')
  const [confirmationCode, setConfirmationCode] = useState('')
  const [errors, setErrors] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isForgotSent, setIsForgotSent] = useState(false)
  const [isConfirmationSent, setIsConfirmationSent] = useState(false)

  const loginAndNavigate = () => {
    let errors = []
    if (_.isEmpty(email)) {
      errors = ['email']
    }

    if (_.isEmpty(password)) {
      errors = errors.concat(['password'])
    }

    if (!_.isEmpty(errors) || isLoading) {
      setErrors(errors)
      return
    }

    setIsLoading(true)

    cognito
      .login(email, password)
      .then(() => {
        onLogin().then(() => {
          setVisible(false)
          setIsLoading(false)
        })
      })
      .catch(error => {
        if (error.message === USER_BLOCKED_MESSAGE) {
          setIsLoading(false)
          setErrorMessage(USER_BLOCKED_MESSAGE)
        } else if (error.name === 'UserNotConfirmedException') {
          setErrors(prev => prev.concat(['unconfirmed']))
          cognito.resendCode(email).then(() => {
            setIsLoading(false)
            setIsConfirmationSent(true)
          })
        } else if (error.name === 'TimeoutError') {
          setIsLoading(false)
          setErrorMessage('Login failed: connection failure')
        } else {
          let err = error.message
          setIsLoading(false)
          if (error.message === 'Incorrect username or password.') {
            err = error.message.replace('username', 'email')
          }
          setErrorMessage(`Login failed. ${err}`)
        }
      })
  }

  const confirmEmail = () => {
    let errors = []
    if (_.isEmpty(email)) {
      errors = ['email']
    }

    if (_.isEmpty(confirmationCode)) {
      errors = errors.concat(['confirmationCode'])
    }

    if (!_.isEmpty(errors) || isLoading) {
      setErrors(errors)
      return
    }

    setIsLoading(true)

    cognito
      .confirm(email, confirmationCode)
      .then(() => {
        setIsConfirmationSent(false)
        loginAndNavigate()
      })
      .catch(_err => {
        setIsLoading(false)
        setErrorMessage('error confirming confirmation code.')
      })
  }

  const forgotPassword = () => {
    let errors = []
    if (_.isEmpty(email)) {
      errors = ['email']
    }

    if (!_.isEmpty(errors) || isLoading) {
      setErrors(errors)
      return
    }

    setIsLoading(true)

    cognito
      .forgotPassword(email)
      .then(() => {
        setIsLoading(false)
        setIsForgotSent(true)
      })
      .catch(_err => {
        setIsLoading(false)
        setErrorMessage('error sending forgot password email.')
      })
  }

  function hasNumber(pass: string) {
    return /\d/.test(pass)
  }

  function hasUpperLower(pass: string) {
    const upper = /[A-Z]/.test(pass),
      lower = /[a-z]/.test(pass)

    return upper && lower
  }

  function hasSpecialCharacter(pass: string) {
    // eslint-disable-next-line no-useless-escape
    return /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(pass)
  }

  const confirmForgotPassword = () => {
    let errors = []
    if (_.isEmpty(email)) {
      errors = ['email']
    }

    if (_.isEmpty(password)) {
      errors = errors.concat(['password'])
    } else {
      if (password.length < 12) {
        errors = errors.concat(['password', 'passwordLength'])
      }

      if (!hasNumber(password)) {
        errors = errors.concat(['password', 'passwordNumber'])
      }

      if (!hasUpperLower(password)) {
        errors = errors.concat(['password', 'passwordCase'])
      }

      if (!hasSpecialCharacter(password)) {
        errors = errors.concat(['password', 'passwordSpecial'])
      }
    }

    if (_.isEmpty(code)) {
      errors = errors.concat(['code'])
    }

    if (!_.isEmpty(errors) || isLoading) {
      setErrors(errors)
      return
    }

    setIsLoading(true)

    cognito
      .confirmForgotPassword(email, password, code)
      .then(() => {
        setIsLoading(false)
        setIsForgotSent(false)
      })
      .catch(_err => {
        setIsLoading(false)
        setErrorMessage('error confirming forgot password code.')
      })
  }

  const handleUserKeyPress = e => {
    if (e.key === 'Enter') {
      if (!e.repeat) {
        isForgotSent ? confirmForgotPassword() : isConfirmationSent ? confirmEmail() : loginAndNavigate()
      }

      e.preventDefault()
    }
  }

  const close = () => {
    setIsForgotSent(false)
    setVisible(false)
    setIsLoading(false)
  }

  const isSent = isForgotSent || isConfirmationSent

  return (
    <div className={`modal-container ${visible && 'modal-container-visible'}`}>
      <div className={`modal-background ${visible && 'modal-background-visible'}`} onClick={close} />
      <div className={`login-modal  ${visible && 'login-modal-visible'} ${isSent && 'login-modal-forgot'}`}>
        <img src={closeIcon} className="close" alt="logo" onClick={close} />
        <img src={logoGif} className="logo-gif" alt="logo" />
        <div className={`h4 modal-header ${isSent && 'modal-header-forgot'}`}>
          {isSent ? 'Confirmation Email Sent!' : 'Welcome Back!'}
        </div>
        {isSent && (
          <div className="reminder-text">
            {isForgotSent
              ? 'To change your password, enter the code sent to your email and provide your new password below.'
              : 'Email not confirmed, we sent you a new email. Confirm the code below.'}
          </div>
        )}
        {!_.isEmpty(errorMessage) && <div className="reminder-text error-text-modal">{errorMessage}</div>}
        <div className="login-container">
          <div className={`xx-small login-input-form input-header ${errors.includes('email') && 'error'}`}>
            Email Address
            <input
              required
              type="email"
              className="input-login"
              name="email"
              onChange={evt => setEmail(evt.target.value)}
              value={email}
            />
          </div>
          <div className={`xx-small login-input-form input-header row ${errors.includes('password') && 'error'}`}>
            <div className="password-input-row">
              {isForgotSent ? 'New ' : ''}Password
              <input
                required
                type={passwordShown ? 'text' : 'password'}
                className="input-login"
                onChange={evt => setPassword(evt.target.value)}
                value={password}
                onKeyDown={handleUserKeyPress}
              />
            </div>
            <img
              src={passwordShown ? eyeClosed : eye}
              className="eye"
              alt="eye"
              onClick={() => setPasswordShown(!passwordShown)}
            />
          </div>
          {errors.includes('passwordLength') && (
            <div className="xx-small requirement-error">
              <img src={error} className="error-icon" alt="error" />
              12+ characters
            </div>
          )}
          {errors.includes('passwordNumber') && (
            <div className="xx-small requirement-error">
              <img src={error} className="error-icon" alt="error" />
              Number
            </div>
          )}
          {errors.includes('passwordCase') && (
            <div className="xx-small requirement-error">
              <img src={error} className="error-icon" alt="error" />
              Upper and lowercase character
            </div>
          )}
          {errors.includes('passwordSpecial') && (
            <div className="xx-small requirement-error">
              <img src={error} className="error-icon" alt="error" />
              Special character
            </div>
          )}
          {isForgotSent && (
            <div className={`xx-small login-input-form input-header row ${errors.includes('code') && 'error'}`}>
              <div className="password-input-row">
                Email Code
                <input
                  required
                  className="input-login"
                  onChange={evt => setCode(evt.target.value)}
                  value={code}
                  onKeyDown={handleUserKeyPress}
                />
              </div>
            </div>
          )}
          {isConfirmationSent && (
            <div
              className={`xx-small login-input-form input-header row ${errors.includes('confirmationCode') && 'error'}`}
            >
              <div className="password-input-row">
                Email Code
                <input
                  required
                  className="input-login"
                  onChange={evt => setConfirmationCode(evt.target.value)}
                  value={confirmationCode}
                  onKeyDown={handleUserKeyPress}
                />
              </div>
            </div>
          )}
          <Button
            type="Submit"
            buttonStyle={`rounded-button-light rounded-button-label-light login-button ${isSent && 'confirm-button'}`}
            onClick={isForgotSent ? confirmForgotPassword : isConfirmationSent ? confirmEmail : loginAndNavigate}
            label={isForgotSent ? 'Confirm Password Change' : isConfirmationSent ? 'Confirm and Login' : 'Login'}
          />
          {isLoading && <Loader small={true} style={{ marginTop: 10 }} />}
          {!isSent && (
            <div className={'small-400 forgot'} onClick={forgotPassword}>
              Forgot your password?
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
