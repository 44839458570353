import { Outlet, ScrollRestoration } from 'react-router-dom'
import logoRed from 'assets/logoRed.svg'
import logoYellow from 'assets/logoYellow.svg'
import logoOrange from 'assets/logoOrange.svg'
import logoBlue from 'assets/logoBlue.svg'
import logoGreen from 'assets/logoGreen.svg'
import { useLocation } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import Header from 'components/header'
import Footer from 'components/footer'
import LoginModal from 'components/login-modal'
import { CognitoClient, EMPTY_PROFILE, EMPTY_USER } from 'lib/cognito'
import { CookiesProvider, useCookies } from 'react-cookie'
import _ from 'lodash'
import { AblyClient } from 'lib/ably'

const LOGOS_MAP = new Map([
  ['/', logoRed],
  ['/about-us', logoYellow],
  ['/partners', logoOrange],
  ['/faq', logoBlue],
  ['/contact', logoGreen],
  ['/host-application', logoYellow]
])

// React-router loading
// export async function loader() {
//   const contacts = [
//     { id: 1, first: 'derek', last: 'watring' },
//     { id: 2, first: 'desrek', last: 'wstring' }
//   ]
//   return { contacts }
// }

export default function Root() {
  const location = useLocation()
  const [logo, setLogo] = useState(null)
  const [visible, setVisible] = useState(null)
  const [user, setUser] = useState(EMPTY_USER)
  const [profile, setProfile] = useState(EMPTY_PROFILE)
  const [cognito] = useState(new CognitoClient())
  const [ably] = useState(new AblyClient(cognito))
  const [cookies, setCookie, removeCookie] = useCookies(['idToken', 'accessToken', 'refreshToken'])

  const logout = useCallback(() => {
    cognito.logout()
    removeCookie('accessToken')
    removeCookie('idToken')
    removeCookie('refreshToken')
    ably.disconnect()
    ably.reset()

    setProfile(EMPTY_PROFILE)
    setUser(EMPTY_USER)
  }, [ably, cognito, removeCookie])

  const initializeSocial = useCallback(
    async (_skipUser: boolean) => {
      console.info('MainLoading - saving data to cookies')
      setCookie('accessToken', cognito.accessToken)
      setCookie('idToken', cognito.idToken)

      if (!_.isNil(cognito.refreshToken)) {
        setCookie('refreshToken', cognito.refreshToken)
      }

      console.info('MainLoading - fetching social info')

      try {
        const cogUser = await cognito.getUser()
        // if (!skipUser) {
        setUser(cogUser)
        // }

        const profile = await cognito.getProfile(cogUser.sub)
        setProfile(profile)
      } catch {
        console.error('error getting user or profile')
      }
    },
    [cognito, setCookie]
  )

  useEffect(() => {
    setLogo(LOGOS_MAP.get(location.pathname) || logoRed)
  }, [location, logo, setLogo])

  useEffect(() => {
    const validateSession = async () => {
      try {
        const validUser = await cognito.getUser()
        setUser(validUser)
        console.info('AuthLoading - Validating social session', validUser.email)

        if (!validUser.email) {
          return false
        } else {
          if (!validUser.emailConfirmed) {
            console.info('AuthLoading - email unconfirmed', validUser.email)
          }

          const isValidSession = !(await cognito.shouldReauthenticate())

          console.info(`AuthLoading - ${isValidSession ? 'validated social session' : 'social needs reauthentication'}`)

          return isValidSession
        }
      } catch (error: any) {
        // InAppMessage.showError({
        //   message: 'Error validating previous session, please log in'
        // })
        console.error('AuthLoading - error getting user to validate session')

        return false
      }
    }

    const tryToRestoreSession = async (): Promise<boolean> => {
      console.info('AuthLoading - attempting to restore session')

      cognito.accessToken = cookies.accessToken
      cognito.idToken = cookies.idToken
      cognito.refreshToken = cookies.refreshToken

      if (!cognito.accessToken || !cognito.idToken) {
        console.info('AuthLoading - No past session')
        return false
      } else {
        if (await validateSession()) {
          await initializeSocial(true)
          return true
        } else {
          logout()
          return false
        }
      }
    }

    tryToRestoreSession()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeCookie, initializeSocial, logout])

  // const { contacts } = useLoaderData();
  return (
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <Header
        useDark={
          location.pathname === '/host-application' ||
          location.pathname === '/privacy-policy' ||
          location.pathname === '/watch'
        }
        logo={logo}
        setLoginModalVisible={setVisible}
        profile={profile}
        logout={logout}
      />
      <div id="detail">
        <LoginModal visible={visible} setVisible={setVisible} onLogin={initializeSocial} cognito={cognito} />
        <Outlet context={[visible, setVisible, profile, user, cognito, ably]} />
      </div>
      <Footer />
      <ScrollRestoration />
    </CookiesProvider>
  )
}
