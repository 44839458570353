import 'styles/partners.css'
import 'styles/text.css'
import tasty from 'assets/tasty.png'
import chart from 'assets/chart.svg'
import chevronRight from 'assets/chevronRight.svg'
import trend from 'assets/trend.svg'
import graph from 'assets/graph.svg'
import chartActive from 'assets/chart-active.svg'
import trendActive from 'assets/trend-active.svg'
import graphActive from 'assets/graph-active.svg'
import chartGif from 'assets/chartGif.gif'
import { useState } from 'react'
import Button from 'components/button'
import Background from 'components/background'
import bg from 'assets/partnerBackground.jpeg'

export default function Partners() {
  const [stockExpanded, setStockExpanded] = useState(false)
  const [optionsExpanded, setOptionsExpanded] = useState(false)
  const [futuresExpanded, setFuturesExpanded] = useState(false)

  const setExpanded = val => () => {
    setStockExpanded(false)
    setOptionsExpanded(false)
    setFuturesExpanded(false)

    if (val === 'stock') {
      setStockExpanded(!stockExpanded)
    }
    if (val === 'options') {
      setOptionsExpanded(!optionsExpanded)
    }
    if (val === 'futures') {
      setFuturesExpanded(!futuresExpanded)
    }
  }

  return (
    <>
      <Background src={bg} tintColor={'#ffc58f'} />
      <div className="header-partners">
        <div className="h1 header-partners-text">Partners on Bad Trader.</div>
        <div className="partner-description-container">
          <img src={chartGif} className="chart-gif" alt="chart" />
          <div className="h5 partner-description-content">
            <span>
              Through our marketing affiliates, Bad Trader allows users to log into their broker and access a wide range
              of products and a simplified selection of strategies. Trading commissions and fees are charged by the
              individual broker. Bad Trader does not charge any fees to its users
            </span>
            <span className="h5 haha"> (even for guac).</span>
          </div>
        </div>
      </div>
      <div className="partner-container">
        <div className="tasty-container">
          <div className="tasty-left">
            <img src={tasty} className="tasty-logo" alt="chaseGus" />
            <div className="h5 tagline">Join the club, genius.</div>
            <a href="https://info.tastytrade.com/join-tastytrade?utm_source=119&utm_medium=internal_link" className="long-button" target="_blank" rel="noreferrer">
              <Button
                buttonStyle="rounded-button-light rounded-button-label-light long-button"
                aria-label={'Download'}
                label="Open a tastytrade account"
              />
            </a>
            <div className="legal legal-margin">
              tastytrade, Inc. (“tastytrade”) has entered into a Marketing Agreement with Bad Trader, LLC (“Bad Trader”)
              whereby tastytrade pays compensation to Bad Trader to recommend tastytrade’s brokerage services and to
              permit orders to be sent to tastytrade through Bad Trader’s App. Bad Trader and tastytrade are separate,
              but affiliated companies. The existence of this Bad Trader should not be deemed as an endorsement or
              recommendation of Bad Trader by tastytrade and/or any of its other affiliated companies. Neither
              tastytrade nor any of its other affiliated companies is responsible for the privacy practices of Bad
              Trader or its website, programs, or App. tastytrade does not warrant the accuracy or content of the
              products or services offered by Bad Trader or its website, programs, or App.
            </div>
          </div>
          <div className="tasty-right">
            <div className="large-500 type-container" onClick={setExpanded('stock')}>
              <div className="h5 type">
                <div className="type-name">
                  <img src={stockExpanded ? chartActive : chart} className="trading-icon" alt="chaseGus" />
                  Stocks & ETFs*
                </div>
                <img src={chevronRight} className="chevron-right" alt="chaseGus" />
              </div>
              <div className={`type-text ${stockExpanded && 'text-expanded'}`}>
                Through their tastytrade account, Bad Trader users can buy or sell stocks and ETFs commission-free* and
                in fractional shares.
              </div>
            </div>
            <div className="large-500 type-container" onClick={setExpanded('options')}>
              <div className="type">
                <div className="type-name">
                  <img src={optionsExpanded ? graphActive : graph} className="trading-icon" alt="chaseGus" />
                  Options
                </div>
                <img src={chevronRight} className="chevron-right" alt="chaseGus" />
              </div>
              <div className={`type-text ${optionsExpanded && 'text-expanded'}`}>
                With a tastytrade margin account, Bad Trader users can utilize a simplified selection of long and short
                options strategies on stocks, ETFs, and broad-based indices.
              </div>
            </div>
            <div className="large-500 type-container" onClick={setExpanded('futures')}>
              <div className="type">
                <div className="type-name">
                  <img src={futuresExpanded ? trendActive : trend} className="trading-icon" alt="chaseGus" />
                  Futures
                </div>
                <img src={chevronRight} className="chevron-right" alt="chaseGus" />
              </div>
              <div className={`type-text ${futuresExpanded && 'text-expanded'}`}>
                With a tastytrade futures account, Bad Trader users can access futures and get exposure to a range of
                commodities products.
              </div>
            </div>
            <div className="legal">
              *All stock trades incur a clearing fee of $0.0008 per share, and applicable exchange and regulatory fees.
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
