import { useLocation } from 'react-router-dom'
import 'styles/button.css'

export default function Button({ label, onClick = () => null, buttonStyle = '', type = 'button' }) {
  const location = useLocation()

  switch (location.pathname) {
    case '/faq':
      buttonStyle = 'button-blue ' + buttonStyle
      break
    case '/about-us':
      buttonStyle = 'button-yellow ' + buttonStyle
      break
    case '/partners':
      buttonStyle = 'button-orange ' + buttonStyle
      break
    case '/contact':
      buttonStyle = 'button-green ' + buttonStyle
      break
    default:
      buttonStyle = 'button-red ' + buttonStyle
      break
  }

  return (
    <button
      className={`small-600 rounded-button rounded-button-label ${buttonStyle}`}
      aria-label={'Download'}
      onClick={onClick}
      type={type as 'button' | 'submit' | 'reset'}
    >
      {label}
    </button>
  )
}
