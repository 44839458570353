import { Types } from 'ably'
import arrowLeft from 'assets/arrow-left.svg'
import Loader from 'components/loader'
import Contentstack from 'contentstack'
import { ConnectionState, getMessage } from 'lib/ably'
import { ChannelDto, EMPTY_PROFILE, UserStatus } from 'lib/cognito'
import { Channel, Host, ScheduledShow, Show } from 'lib/contentstack'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useOutletContext } from 'react-router-dom'
import 'styles/text.css'
import 'styles/watch.css'

const CHANNEL_CHAT_IN = 'bad_trader:chat:in'
const NEW_MESSAGES_ID = '_[NEW_MESSAGES]_'

type MessageOut = {
  displayName: string
  version: number
  message: string
  timestamp: string // format 2022-12-19T17:00:01Z
  channelName: string
  userToken: string
  streamIsLive: boolean
}

type MessageIn = {
  version: number
  displayName: string
  clientId: string
  message: string
  messageId: string
  timestamp: string // format 2022-12-19T17:00:01Z
}

export default function Watch() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_visible, setVisible, profile, user, cognito, ably] = useOutletContext() as any
  const [cookies] = useCookies(['refreshToken'])
  const [message, setMessage] = useState('')
  let shows = useRef(new Map<string, Show>())
  let hosts = useRef(new Map<string, Host>())
  let sortedDaySchedule = useRef([])
  let mainChannel = useRef({
    title: '',
    description: '',
    visible_title: '',
    url: { href: '', title: '' },
    schedule: []
  } as Channel)
  const ref = useRef(null)
  const calendarItemRef = useRef(null)
  const [videoHeight, setVideoHeight] = useState(0)
  const [tabIndex, setTabIndex] = useState(0)
  const [chatHeight, setChatHeight] = useState(0)
  const [isSmall, setIsSmall] = useState(false)
  const [currentLiveShow, setCurrentLiveShow] = useState(null as ScheduledShow)
  const [lastLiveShow, setLastLiveShow] = useState(null as ScheduledShow)
  const [isLive, setIsLive] = useState(false)
  const [isChatLoading, setIsChatLoading] = useState(true)
  const [isVideoLoading, setIsVideoLoading] = useState(true)
  const [streamUrl, setStreamUrl] = useState('')
  let channel = useRef(null as Types.RealtimeChannelCallbacks)
  let channelStatus = useRef(null as Types.RealtimeChannelCallbacks)
  const [showData, setShowData] = useState(null as ScheduledShow)
  const [messages, setMessages] = useState([] as MessageIn[])
  const messagesEndRef = useRef(null)
  let channelName = useRef(null as string)
  let channelId = useRef(null as string)

  const autoGrow = e => {
    e.target.style.height = '5px'
    const height = e.target.scrollHeight < 40 ? e.target.scrollHeight : e.target.scrollHeight + 5
    e.target.style.height = height + 'px'
  }

  const handleResize = () => {
    setIsSmall(window.innerWidth < 799)
    const videoHeight = Math.min(window.innerHeight - 80 - 24 - 60, ref.current.offsetWidth * (50 / 89))
    setChatHeight(window.innerHeight - videoHeight - 80 - 62 - 16 - 34 - 16)
    setVideoHeight(videoHeight)
  }

  useLayoutEffect(() => {
    handleResize()
  }, [])

  const handleUserKeyPress = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()

      if (_.isEmpty(profile.id)) {
        setVisible(true)
      }

      const messageToSend = message.trim()

      if (_.isEmpty(messageToSend)) {
        return
      }

      const messageData: MessageOut = {
        version: 1,
        displayName: profile.displayName,
        userToken: cognito.idToken,
        message: messageToSend,
        timestamp: moment().format(),
        channelName: channelName.current,
        streamIsLive: isLive
      }

      ably.publish(CHANNEL_CHAT_IN, messageData)
      setMessage('')
    }
  }

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
  }, [messages])

  useEffect(() => {
    setIsLive(!_.isNil(currentLiveShow))
  }, [currentLiveShow])

  useEffect(() => {
    setShowData(isLive ? currentLiveShow : lastLiveShow)
  }, [isLive, currentLiveShow, lastLiveShow])

  const channelStatusEvent = messageData => {
    const message = getMessage(messageData)
    const type = message?.type
    if (type !== 'channel.stream.updated') {
      return
    }

    if (channelName.current === message?.channelName) {
      console.info('ChannelStore channelEvent', type, message)

      if (streamUrl !== message?.streamUrl) {
        setStreamUrl(message?.streamUrl)
      }

      if (channelId.current !== message?.channelId) {
        channelId.current = message?.channelId
      }
    }
  }

  const channelChatEvent = messageData => setMessages(prev => prev.slice(0, 60).concat([getMessage(messageData)]))

  const subscribe = (i: number) => {
    const getUserStatus = () => {
      return _.isEmpty(profile.status) ? EMPTY_PROFILE.status : profile.status
    }

    if (ably.client?.connection.state !== ConnectionState.CONNECTED) {
      console.info(`watch - aborting ably connection - state: ${ably.client?.connection.state}`)
      const timeout = 500 * 2 ** i
      console.info(`watch - deferring ably connection for ${timeout / 1000} seconds`)
      setTimeout(() => subscribe(i + 1), timeout)
      return
    }

    if (getUserStatus() === UserStatus.Banned) {
      console.info('watch - aborting ably connection - user banned')
      return
    }

    // Sub to channel status changes
    const channelStatusOut = 'bad_trader:channel:status:out'
    channelStatus.current = ably.getChannel(channelStatusOut)
    if (!_.isNil(channelStatus.current)) {
      channelStatus.current.subscribe(channelStatusEvent)
    } else {
      console.info('watch - aborting ably status connection - no channel found')
    }

    // Sub to channel chat
    const channelChatOut = _.isNil(channelName.current) ? null : `bad_trader:chat:${channelName.current}:out`
    if (!_.isNil(channelChatOut)) {
      channel.current = ably.getChannel(channelChatOut)
    } else {
      console.info('watch - aborting ably chat connection - no channel found')
      return
    }

    if (!_.isNil(channel.current)) {
      channel.current.subscribe(channelChatEvent)

      channel.current.history((_err, resultPage) => {
        const items = resultPage?.items
        const rawMessages = items.map(getMessage)

        // if (!_.isNil(rootStore.notificationStore.lastReadChatTime)) {
        //   const lastReadChatTime = moment(lastReadChatTime)

        //   if (lastReadChatTime.isAfter(moment().subtract(1, 'day'))) {
        //     const newMessageMarker = {
        //       version: 1,
        //       displayName: NEW_MESSAGES_ID,
        //       message: NEW_MESSAGES_CONTENT,
        //       messageId: NEW_MESSAGES_ID,
        //       timestamp: rootStore.notificationStore.lastReadChatTime.toISOString() // format 2022-12-19T17:00:01Z
        //     }

        //     rawMessages.push(newMessageMarker)
        //   }
        // }

        // order items by timestamp
        const historyMessages = _.sortBy(rawMessages, message => new Date(message?.timestamp))

        if (!_.isEmpty(historyMessages) && _.first(historyMessages).messageId === NEW_MESSAGES_ID) {
          historyMessages.shift()
        }

        setMessages(prev => prev.concat(historyMessages))
      })

      // Sub to presence
      const channelPresence = `bad_trader:presence:${channelName.current}`
      const presenceEntered = ably.enterPresenceChannel(channelPresence, user.username, getUserStatus())
      if (!presenceEntered) {
        console.error('Error entering presence channel. User will not display in participants.')
      } else {
        setIsChatLoading(false)
      }
    }
  }

  // Get stream URL
  useEffect(() => {
    if (!cognito.loggedIn) {
      console.info('watch - rejecting stream fetch - not logged in')
      return
    }

    const getStreamData = () => {
      console.info('watch - fetching stream data')
      cognito
        .getCurrentChannel()
        .then((resp: { channelName: string; channel: ChannelDto }) => {
          setStreamUrl(resp.channel.streamUrl)
          setIsVideoLoading(false)

          if (channelName.current !== resp.channelName) {
            channelName.current = resp.channelName
            if (_.isNil(ably.client)) {
              console.info('watch - initializing ably')
              ably.init()
              subscribe(0)
            }
            // TODO: Disconnect from old channel and old channel presence
          }

          setTimeout(getStreamData, 60 * 10 * 1000)
        })
        .catch(_err => console.error('error loading stream data'))
    }

    getStreamData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cognito.loggedIn])

  // ComponentDidMount
  useEffect(() => {
    if (!_.isNil(ref.current)) {
      window.addEventListener('resize', handleResize)
    }

    const stack = Contentstack.Stack({
      api_key: 'blt7dfbfed3d53813b9',
      delivery_token: 'csb410fca7f78a5bb853c98799',
      environment: 'production'
    })

    // Get Channel
    const fetchContentStackData = async () => {
      console.info('watch - fetching content stack data')

      const hostsPromise = stack.ContentType('host').Query().toJSON().find()

      const channelPromise = stack.ContentType('channel').Entry('blt08d460fb2a77694d').fetch()

      const showPromise = stack.ContentType('show').Query().toJSON().find()

      await Promise.all([channelPromise, showPromise, hostsPromise])

      await channelPromise.then(
        channel => {
          mainChannel.current = channel.toJSON()
        },
        _err => {
          console.error('content stack main channel error')
        }
      )

      await showPromise.then(
        showsResp => {
          shows.current = new Map(_.compact(showsResp[0] as Show[]).map(show => [show.uid, show]))
        },
        _err => {
          console.error('content stack hub shows error')
        }
      )

      await hostsPromise.then(
        hostsList => {
          hosts.current = new Map(
            _.compact(hostsList[0] as Host[]).map(host => [host.uid, { firstName: host.name.split(' ')[0], ...host }])
          ) as Map<string, Host>
        },
        _err => {
          console.error('content stack hosts error')
        }
      )

      mainChannel.current.schedule.forEach(scheduledShow => {
        scheduledShow.start_time = new Date(scheduledShow.start_time)
        if (!_.isNil(scheduledShow.show[0])) {
          scheduledShow.show = shows.current.get(scheduledShow.show[0].uid)
          scheduledShow.show.hosts = scheduledShow.show.hosts.map(host => hosts.current.get(host.uid))
        }

        scheduledShow.additional_shows.forEach(additionalShow => {
          additionalShow.show = shows.current.get(additionalShow.show[0]?.uid)
          additionalShow.show.hosts = additionalShow.show.hosts.map(host => hosts.current.get(host.uid))
        })
      })

      const entireSortedSchedule = _.sortBy(mainChannel.current.schedule, scheduledShow =>
        scheduledShow.start_time.getTime()
      )
      const index = _.findLastIndex(entireSortedSchedule, scheduledShow =>
        moment(scheduledShow.start_time.getTime()).add(scheduledShow.duration, 'minutes').isBefore(moment())
      )

      setLastLiveShow(entireSortedSchedule[index])

      const daySchedule = mainChannel.current.schedule.filter(show => moment(show.start_time).isSame(moment(), 'date'))
      sortedDaySchedule.current = _.sortBy(daySchedule, scheduledShow => scheduledShow.start_time)

      if (_.isNil(sortedDaySchedule.current)) {
        return
      }

      setCurrentLiveShow(
        sortedDaySchedule.current.find(scheduledShow =>
          moment().isBetween(
            moment(scheduledShow.start_time),
            moment(scheduledShow.start_time).add(scheduledShow.duration, 'minutes')
          )
        )
      )
    }

    fetchContentStackData()

    // componentWillUnmount
    return () => {
      setVisible(false)
      console.info('watch - leaving ably presence')

      const channelPresence = `bad_trader:presence:${channelName.current}`
      const presenceLeft = ably.leavePresenceChannel(channelPresence)
      if (!presenceLeft) {
        console.error('Error leaving presence channel. User will not be removed from participants.')
      }
      window.removeEventListener('resize', handleResize)

      const channelChatOut = _.isNil(channelName.current) ? null : `bad_trader:chat:${channelName.current}:out`
      const channel = ably.getChannel(channelChatOut)
      const channelStatusOut = 'bad_trader:channel:status:out'
      const channelStatus = ably.getChannel(channelStatusOut)
      if (channel) {
        channel.current.unsubscribe(channelChatEvent)
      }
      if (channelStatus) {
        channelStatus.current.unsubscribe(channelStatusEvent)
      }

      ably.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setVisible(_.isEmpty(cookies.refreshToken))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies.refreshToken])

  const getHostStyle = (host: Host) => ({
    color: host.colors.neon,
    textShadow: `0px 0px 4px ${host.colors.glow}`,
    boxShadow: `0px 0px 1px 0px #fd0015 inset, 0px 0px 4px 0px ${host.colors.glow}`,
    border: `1px solid ${host.colors.neon}`,
    background: host.colors.dark_neon
  })

  return (
    <>
      <div className="watch-container">
        <div className="content-watch">
          <div className="content-left">
            <div className="video" style={{ height: videoHeight }} ref={ref}>
              {/* {_.isEmpty(profile.id) ? 'Login to view the stream!' : ''} */}
              {isVideoLoading ? (
                <Loader />
              ) : (
                // <ReactPlayer
                //   url={`https://edge.api.brightcove.com/playback/v1/accounts/6415628185001/videos/${videoIdSplit[1]}/master.m3u8?bcov_auth=${this.key}`}
                //   width="100%"
                //   height="100%"
                //   muted={true}
                //   playing={true}
                //   controls={true}
                // />
                <iframe src={streamUrl} height="100%" width="100%" title="stream" className="video-stream" />
              )}
            </div>
            <div className="h5 watch-header">
              <div className="watch-left">
                <div className="watch-header-left-container">
                  <div className="watch-title">{_.isNil(showData) ? 'Loading' : showData.show.title}</div>
                  {!_.isNil(currentLiveShow) && (
                    <div className="small-600 live">
                      {currentLiveShow.is_playback ? 'Playback' : 'Live'}
                      {!currentLiveShow.is_playback && <div className="live-dot" />}
                    </div>
                  )}
                </div>
                {!_.isNil(showData) && (
                  <div className="hosts-container-small">
                    <div className="small-600 host" style={getHostStyle(showData.show.hosts[0])}>
                      <img src={showData.show.hosts[0].profile_photo_small.url} className="host-image" alt="host" />@
                      {showData.show.hosts[0].firstName}
                    </div>
                    <div className="small-600 host" style={getHostStyle(showData.show.hosts[1])}>
                      <img src={showData.show.hosts[1].profile_photo_small.url} className="host-image" alt="host" />@
                      {showData.show.hosts[1].firstName}
                    </div>
                  </div>
                )}
              </div>
              {!_.isNil(showData) && (
                <div className="large-500 watch-right">
                  Runtime: {moment(showData.start_time).format('h:mma')} -{' '}
                  {moment(showData.start_time).add(showData.duration, 'minutes').format('h:mma')}
                  {!moment(showData.start_time).isSame(moment(), 'date') &&
                    ` (${moment(showData.start_time).format('dddd')})`}
                </div>
              )}
            </div>
            <div className="tab-container">
              <div
                className={`tab-item tab-title ${tabIndex === 0 && 'tab-title-active tab-item-active'}`}
                onClick={() => setTabIndex(0)}
              >
                Chat
              </div>
              <div
                className={`tab-item tab-title ${tabIndex === 1 && 'tab-title-active tab-item-active'}`}
                onClick={() => setTabIndex(1)}
              >
                Schedule
              </div>
              <div
                className={`tab-item tab-title ${tabIndex === 2 && 'tab-title-active tab-item-active'}`}
                onClick={() => setTabIndex(2)}
              >
                Description
              </div>
            </div>
            {((isSmall && tabIndex === 2) || !isSmall) && (
              <div className="show-container">
                <div className="large-600 show-description">Show description</div>
                {!_.isNil(showData) && <div className="show-info">{showData.show.description}</div>}
              </div>
            )}
            {((isSmall && tabIndex === 1) || !isSmall) && (
              <div className="calendar-container">
                <div className="h5 watch-header">Today's Schedule</div>
                <div className="calendar">
                  <div className="calendar-column">
                    <div className="calendar-item calendar-item-header" ref={calendarItemRef}>
                      <div className="calendar-header"></div>
                    </div>
                    <div className="small-600 calendar-item calendar-big calendar-left">
                      <div className="calendar-content">Bad Trader</div>
                    </div>
                  </div>
                  {_.range(moment().hour(), 24).map((_item, index) => {
                    const itemWidth = 100
                    let location = 0
                    let width = 0
                    const timeStart = moment().add(index, 'hour').startOf('hour')
                    const timeEnd = moment()
                      .add(index + 1, 'hour')
                      .startOf('hour')

                    const showWithinTime: ScheduledShow = sortedDaySchedule.current.find(
                      scheduledShow =>
                        moment(scheduledShow.start_time).isSameOrAfter(timeStart) &&
                        moment(scheduledShow.start_time).isBefore(timeEnd)
                    )
                    if (showWithinTime) {
                      width = itemWidth * (showWithinTime.duration / 60)
                      location = (itemWidth * moment(showWithinTime.start_time).minutes()) / 60
                    }
                    return (
                      <div className="calendar-column" key={timeStart.toString()}>
                        <div className="calendar-item calendar-item-header" ref={calendarItemRef}>
                          <div className="calendar-header">{timeStart.format('h:00 a')}</div>
                        </div>
                        <div className="calendar-item calendar-big empty-show">
                          {showWithinTime && (
                            <div className="small-600 show show-name" style={{ width, left: location }}>
                              {showWithinTime.show.title}
                              <div className="show-description">
                                {showWithinTime.show.description.substring(0, 30)}
                                {showWithinTime.show.description.length > 30 ? '...' : ''}
                              </div>
                            </div>
                          )}
                          <div className="calendar-content">No Scheduled Program</div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
          </div>
          {((isSmall && tabIndex === 0) || !isSmall) && (
            <div className="chat-container" style={isSmall ? { height: chatHeight } : {}}>
              <div className="chat">
                {_.isNil(ably.client) ||
                isChatLoading ||
                ably.client?.connection.state !== ConnectionState.CONNECTED ? (
                  <Loader />
                ) : (
                  <>
                    {messages.map(item => (
                      <div className="chat-line" key={item.messageId}>
                        <span className="chat-name">{item.displayName}</span>: {item.message}
                      </div>
                    ))}
                    <div ref={messagesEndRef} />
                  </>
                )}
              </div>
              <div className="chat-send">
                <img src={arrowLeft} className="arrow-right-watch" alt="arrow" />
                <div className="send">
                  <textarea
                    className="input-watch"
                    name="fname"
                    autoComplete="off"
                    value={message}
                    onChange={evt => setMessage(evt.target.value)}
                    placeholder="Send a message"
                    maxLength={500}
                    onInput={autoGrow}
                    onKeyDown={handleUserKeyPress}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
