import { useEffect } from 'react'
import 'styles/contact.css'
import 'styles/text.css'

export default function Download() {
  const getOs = (values: string[]) => {
    return values.find(v => ((global as any).window?.navigator.userAgent.indexOf(v) >= 0));
  }

  useEffect(() => {
    window.location.replace('/#download')

    if (getOs(['iPhone', 'iPod', 'iPad']) && !(global as any).window?.MSStream) {
      window.location.href = 'https://apps.apple.com/us/app/bad-trader/id1638952698'
      return
    }

    if (getOs(['Android'])) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.tastyworks.mobile.badtrader.android&hl=en_US&gl=US'
      return
    }

  }, [])

  return null
}
