import { CognitoIdentityClient, GetCredentialsForIdentityCommand, GetIdCommand } from '@aws-sdk/client-cognito-identity'
import {
  AuthFlowType,
  CognitoIdentityProviderClient,
  ConfirmForgotPasswordCommand,
  ConfirmSignUpCommand,
  ForgotPasswordCommand,
  GetUserCommand,
  InitiateAuthCommand,
  ResendConfirmationCodeCommand
  // SignUpCommand
} from '@aws-sdk/client-cognito-identity-provider'
import { FetchHttpHandler } from '@aws-sdk/fetch-http-handler'
import { Signer } from 'aws-amplify'
import axios, { AxiosResponse } from 'axios'
import _ from 'lodash'

const userPoolId = 'us-east-2_UG1vUltUo'
const identityPoolId = 'us-east-2:23386ff0-77f8-48e5-8d0a-58b37d897da3'
const clientId = '7ork94bfvjtcfln9jav4cnlrd2'
const host = 'https://api-v2.badtrader.com/v2'
const STREAM_CHANNELS = ['prod_channel', 'test']

export enum UserStatus {
  Active = 'Active',
  Muted = 'Muted',
  Banned = 'Banned',
  Blocked = 'Blocked'
}

export const EMPTY_USER: User = {
  id: '',
  email: null,
  emailConfirmed: null,
  username: null,
  sub: ''
}

export const EMPTY_PROFILE: Profile = {
  StatusUpdateTimestamp: '',
  createDate: '',
  displayName: '',
  id: '',
  muteStrikes: 0,
  pointsBalance: 0,
  status: UserStatus.Active,
  statusReason: '',
  bannerId: 'BannerFour',
  avatarId: 'Rocket'
  // vaultItems: []
}

export interface User {
  id: string
  email: string
  emailConfirmed: boolean
  username: string
  sub: string
}

export interface Profile {
  StatusUpdateTimestamp: string
  createDate: string
  displayName: string
  id: string
  muteStrikes: number
  pointsBalance: number
  status: string
  statusReason: string
  bannerId: string
  avatarId: string
  // vaultItems: VaultItem[]
}

export interface ChannelDto {
  id: string
  name: string
  status: string
  streamUrl: string
}

export class CognitoClient {
  identityClient = new CognitoIdentityClient({ region: 'us-east-2' })
  awsCredentials = null
  idToken = null
  loggedIn = false
  accessToken = null
  refreshToken = null

  // @ts-ignore
  client = new CognitoIdentityProviderClient({
    credentials: () => this.identityCredentials(),
    region: 'us-east-2',
    // @ts-ignore
    requestHandler: new FetchHttpHandler({
      requestTimeout: 5000
    }),
    maxAttempts: 2
  })

  private _login(username: string, password: string) {
    const command = new InitiateAuthCommand({
      ClientId: clientId,
      AuthFlow: AuthFlowType.USER_PASSWORD_AUTH,
      AuthParameters: { USERNAME: username, PASSWORD: password }
    })

    return this.authenticate(command)
  }

  login(email: string, password: string) {
    console.info('logging in to social')
    return this._login(email, password).then(response => {
      this.storeLoginResponse(response)
    })
  }

  forgotPassword(username) {
    const command = new ForgotPasswordCommand({
      ClientId: clientId,
      Username: username
    })

    return this.client.send(command)
  }

  confirmForgotPassword(username, password, code) {
    const command = new ConfirmForgotPasswordCommand({
      ClientId: clientId,
      Username: username,
      Password: password,
      ConfirmationCode: code
    })

    return this.client.send(command)
  }

  confirm(username, confirmationCode) {
    const command = new ConfirmSignUpCommand({
      ClientId: clientId,
      Username: username,
      ConfirmationCode: confirmationCode
    })

    return this.client.send(command)
  }

  resendCode(username) {
    const command = new ResendConfirmationCodeCommand({
      ClientId: clientId,
      Username: username
    })

    return this.client.send(command)
  }

  authenticate = (command: InitiateAuthCommand) => {
    // capture existing refresh token for AuthFlowType.REFRESH_TOKEN_AUTH, see shouldReauthenticate
    const currentRefreshToken = command.input.AuthParameters['REFRESH_TOKEN']
    return this.client.send(command).then(response => {
      if (!_.isNil(response.AuthenticationResult?.IdToken)) {
        this.accessToken = response.AuthenticationResult?.AccessToken
        this.idToken = response.AuthenticationResult?.IdToken
        // not guaranteed to get new refresh token for flow AuthFlowType.REFRESH_TOKEN_AUTH
        this.refreshToken = response.AuthenticationResult?.RefreshToken || currentRefreshToken

        return this.getScopedCreds().then(awsCredentials => {
          return {
            idToken: this.idToken,
            accessToken: this.accessToken,
            refreshToken: this.refreshToken,
            awsCredentials: awsCredentials
          }
        })
      }

      return null
    })
  }

  // signUp(username, password, nickname) {
  //   const command = new SignUpCommand({
  //     ClientId: appId,
  //     Username: username,
  //     Password: password,
  //     UserAttributes: [{ Name: 'preferred_username', Value: nickname }] // may not be needed
  //   })

  //   return this.client.send(command)
  // }

  identityCredentials = () => {
    return this.getScopedCreds().then(response => {
      return {
        accessKeyId: response.AccessKeyId,
        expiration: response.Expiration,
        secretAccessKey: response.SecretKey,
        sessionToken: response.SessionToken
      }
    })
  }

  getScopedCreds() {
    const Logins = { [`cognito-idp.us-east-2.amazonaws.com/${userPoolId}`]: this.idToken }
    const getIdCommand = new GetIdCommand({
      IdentityPoolId: identityPoolId,
      Logins
    })

    return this.identityClient.send(getIdCommand).then(idResponse => {
      const getCredsCommand = new GetCredentialsForIdentityCommand({
        IdentityId: idResponse.IdentityId,
        Logins
      })

      return this.identityClient.send(getCredsCommand).then(credentialsResponse => {
        this.awsCredentials = credentialsResponse.Credentials
        return this.awsCredentials
      })
    })
  }

  signedRequest = (request: any) => {
    const accessInfo = {
      access_key: this.awsCredentials?.AccessKeyId,
      secret_key: this.awsCredentials?.SecretKey,
      session_token: this.awsCredentials?.SessionToken
    }

    const serviceInfo = {
      service: 'execute-api',
      region: 'us-east-2'
    }

    const signedRequest = Signer.sign(request, accessInfo, serviceInfo)
    delete signedRequest.headers['host']

    const instance = axios.create()

    return instance(signedRequest)
  }

  getTokenRequest = async () => {
    const response = await this.signedRequest({
      method: 'POST',
      url: `${host}/ps/auth/tokens`,
      data: ''
    })

    return response?.data
  }

  getProfile = (id: string) => {
    const url = `${host}/ps/profile/${id}`
    return this.signedRequest({
      method: 'GET',
      url: url,
      data: ''
    }).then(response => {
      return response.data
    })
  }

  shouldReauthenticate = async () => {
    const command = new InitiateAuthCommand({
      ClientId: clientId,
      AuthFlow: AuthFlowType.REFRESH_TOKEN_AUTH,
      AuthParameters: { REFRESH_TOKEN: this.refreshToken }
    })

    return this.authenticate(command)
      .then(response => {
        this.storeLoginResponse(response)
        return false
      })
      .catch(_error => {
        console.error('error reauthenticating social login')
        return true
      })
  }

  logout = () => {
    this.accessToken = null
    this.idToken = null
    this.refreshToken = null
    this.loggedIn = false
  }

  getChannelData = (): Promise<ChannelDto[]> => {
    const url = `${host}/shs/channels`

    return this.signedRequest({
      method: 'GET',
      url: url,
      data: ''
    }).then((response: AxiosResponse<ChannelDto[]>) => {
      return response.data
    })
  }

  getCurrentChannel = () => {
    return this.getChannelData().then(channels => {
      for (const channelName of STREAM_CHANNELS) {
        const channel = channels.find(channel => channel.name === channelName && channel.status === 'Active')

        if (!_.isNil(channel)) {
          return { channelName, channel }
        }
      }

      console.error('no current stream found')
      return null
    })
  }

  storeLoginResponse = response => {
    this.accessToken = response.accessToken
    this.idToken = response.idToken

    if (!_.isNil(response.refreshToken)) {
      this.refreshToken = response.refreshToken
    }

    this.loggedIn = true
  }

  getUser = () => {
    const command = new GetUserCommand({
      AccessToken: this.accessToken
    })

    return this.client.send(command).then(response => {
      return this.returnUserFromResponse(response)
    })
  }

  returnUserFromResponse = (response: any) => {
    let user = EMPTY_USER

    if (!_.isNil(response.UserAttributes)) {
      const userAttributes = { email: '', email_verified: false, preferred_username: '', sub: '' }

      response.UserAttributes.forEach(obj => (userAttributes[obj.Name] = obj.Value))
      user = {
        ...user,
        id: response.Username,
        email: userAttributes.email,
        emailConfirmed: userAttributes.email_verified,
        username: userAttributes.preferred_username,
        sub: userAttributes.sub
      }
    }

    return user
  }
}
