import { useState } from 'react'
import 'styles/contact.css'
import 'styles/text.css'
import arrowLeft from 'assets/arrow-left.svg'
// import arrow from 'assets/arrow.gif'
import submitGif from 'assets/submitGif.gif'
import Button from 'components/button'
import bug from 'assets/bug.gif'
// import color from 'assets/color.gif'
import handshake from 'assets/handshake.gif'
import join from 'assets/join.gif'
import other from 'assets/other.gif'
import suggestion from 'assets/suggestion.gif'
import support from 'assets/support.gif'
import _ from 'lodash'
import Background from 'components/background'
import bg from 'assets/aboutUsBackground.jpeg'

const gifMap = new Map([
  ['Other', other],
  ['Join the Team', join],
  ['Become a Marketing Affiliate', handshake],
  ['Suggest a Feature', suggestion],
  ['Report a Bug', bug],
  ['Bad Trader Support', support]
])

export default function Contact() {
  const [expanded, setExpanded] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const [topic, setTopic] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const gif = gifMap.get(topic)

  const submit = () => {
    setSubmitted(true)
  }

  const isFull =
    !_.isEmpty(message) && !_.isEmpty(email) && !_.isEmpty(lastName) && !_.isEmpty(firstName) && !_.isEmpty(topic)

  return (
    <>
      <Background src={bg} tintColor={'#8fffa7'} />
      <div className="header-contact">
        <div className="h1 header-left-contact">
          Hit us
          <br />
          up.
          {/* <img src={arrow} className="arrow" alt="chaseGus" /> */}
        </div>
        <div className="header-right-contact">
          {submitted ? (
            <div className="h3 submit-container">
              We'll get back to you soon!
              <img src={submitGif} className="submit-gif" alt="gif" />
              <Button
                buttonStyle="small-600 rounded-button-mail rounded-button-label-mail"
                aria-label={'Download'}
                onClick={() => setSubmitted(false)}
                label="Ask Another"
              />
            </div>
          ) : (
            <>
              <div
                className={`small-600 text-input-form contact-highlight ${expanded && 'text-input-expanded'}`}
                onClick={() => setExpanded(!expanded)}
              >
                <div className="dropdown">
                  {!_.isEmpty(topic) && <img src={gif} className="topic-gif" alt="topic" />}
                  <div>{topic || 'Pick a topic...'}</div>
                  <img src={arrowLeft} className={`arrow-right-contact ${expanded && 'arrow-animate'}`} alt="arrows" />
                </div>
                <ul className={`dropdown-container ${expanded && 'dropdown-expanded'}`}>
                  <li className="item" onClick={() => setTopic('Bad Trader Support')}>
                    Bad Trader Support
                  </li>
                  <li className="item" onClick={() => setTopic('Report a Bug')}>
                    Report a Bug
                  </li>
                  <li className="item" onClick={() => setTopic('Suggest a Feature')}>
                    Suggest a Feature
                  </li>
                  <li className="item" onClick={() => setTopic('Become a Marketing Affiliate')}>
                    Become a Marketing Affiliate
                  </li>
                  <li className="item" onClick={() => setTopic('Join the Team')}>
                    Join the Team
                  </li>
                  <li className="item" onClick={() => setTopic('Other')}>
                    Other
                  </li>
                </ul>
              </div>
              <div className="name-container">
                <div className="xx-small text-input-form">
                  First Name
                  <input
                    type="text"
                    className="input-contact"
                    name="fname"
                    value={firstName}
                    onChange={evt => setFirstName(evt.target.value)}
                  />
                </div>
                <div className="xx-small text-input-form">
                  Last Name
                  <input
                    type="text"
                    className="input-contact"
                    name="lname"
                    value={lastName}
                    onChange={evt => setLastName(evt.target.value)}
                  />
                </div>
              </div>
              <div className="xx-small text-input-form">
                Email
                <input
                  type="email"
                  className="input"
                  name="email"
                  value={email}
                  onChange={evt => setEmail(evt.target.value)}
                />
              </div>
              <div className="xx-small text-input-form big">
                Leave a Message
                <textarea
                  className="input big-input"
                  name="message"
                  value={message}
                  onChange={evt => setMessage(evt.target.value)}
                />
              </div>
              <a
                href={
                  !isFull
                    ? null
                    : `mailto:support@badtrader.app?subject=${topic}%20-%20${firstName}%20${lastName}&body=${message}`
                }
                onClick={submit}
                className="submit-form"
              >
                <Button
                  buttonStyle="rounded-button-mail rounded-button-label-mail submit-button"
                  aria-label={'submit'}
                  label="Submit"
                />
              </a>
              <div className="brokerage-warning">
                <div className="h3 brokerage-warning-header">Brokerage questions?</div>
                <div className="large-500 brokerage-warning-text">
                  Check out tastytrade’s support page{' '}
                  <a className="brokerage-link" href="https://support.tastyworks.com/support/home">
                    here
                  </a>
                  .
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}
