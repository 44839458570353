import 'styles/header.css'
import 'styles/text.css'
import { Link } from 'react-router-dom'
import tiktok from 'assets/tiktok.svg'
import youtube from 'assets/youtube.svg'
import logoRed from 'assets/logoRed.svg'
import insta from 'assets/insta.svg'
import twitter from 'assets/twitter.svg'
import hamburger from 'assets/hamburger.svg'
import close from 'assets/close.svg'
import Button from 'components/button'
import logoHover from 'assets/logoHover.svg'
import { useEffect, useState } from 'react'
import _ from 'lodash'

export default function Header({ useDark, logo = logoRed, setLoginModalVisible, profile, logout = _.noop }) {
  const [expanded, setExpanded] = useState(false)

  const openLoginOnMobile = () => {
    setLoginModalVisible(true)
    setExpanded(false)
  }

  const [scrollPosition, setScrollPosition] = useState(0)
  const handleScroll = () => {
    // TODO: THROTTLE THIS.
    const position = window.scrollY
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  let containerStyle = ''
  let textStyle = ''
  let iconStyle = ''
  let buttonStyle = ''
  let hamburgerStyle = ''

  if (useDark || scrollPosition > 720) {
    containerStyle = 'dark-header-row'
    textStyle = 'dark-header-text'
    iconStyle = 'dark-header-icon'
    buttonStyle = 'small-600 rounded-button-light rounded-button-label-light'
    hamburgerStyle = 'dark-menu'
  }

  return (
    <>
    {/* <ScrollToAnchor /> */}
      <div className={`topBar ${containerStyle}`}>
        <div
          className={`mobile-background ${expanded && 'mobile-background-expanded'}`}
          onClick={() => setExpanded(false)}
        />
        <div className={`mobile-tray ${expanded && 'mobile-tray-expanded'}`}>
          <div className="tray-container">
            <img src={close} className="close" alt="logo" onClick={() => setExpanded(false)} />
            <Link className="h5 tray-text" to={'about-us'} onClick={() => setExpanded(false)}>
              About Us
            </Link>
            <Link className="h5 tray-text" to={'partners'} onClick={() => setExpanded(false)}>
              Partners
            </Link>
            <Link className="h5 tray-text" to={'watch'} onClick={() => setExpanded(false)}>
              Watch
            </Link>
            <a href="https://www.tiktok.com/@badtraderapp" className="h5 tray-text" target="_blank" rel="noreferrer">
              <img src={tiktok} className="social dark-header-icon" alt="logo" /> TikTok
            </a>
            <a href="https://www.youtube.com/@badtraderapp/" className="h5 tray-text" target="_blank" rel="noreferrer">
              <img src={youtube} className="social dark-header-icon" alt="logo" /> Youtube
            </a>
            <a href="https://www.instagram.com/badtraderapp/" className="h5 tray-text" target="_blank" rel="noreferrer">
              <img src={insta} className="social dark-header-icon" alt="logo" /> Instagram
            </a>
            <a href="https://twitter.com/BadTraderApp" className="h5 tray-text" target="_blank" rel="noreferrer">
              <img src={twitter} className="social dark-header-icon" alt="logo" /> Twitter
            </a>
          </div>
          <div className="tray-footer">
            {_.isEmpty(profile.id) ? (
              <Button
                label={'Login'}
                buttonStyle="rounded-button-light rounded-button-label-light tray-button"
                onClick={openLoginOnMobile}
              />
            ) : (
              <Link to={'.'}>
                <Button
                  label={'Logout'}
                  buttonStyle="rounded-button-light rounded-button-label-light tray-button"
                  onClick={logout}
                />
              </Link>
            )}
            <Link to="/#download" onClick={() => setExpanded(false)}>
              <Button label="Download" buttonStyle="rounded-button-light rounded-button-label-light tray-button" />
            </Link>
          </div>
        </div>
        <div className="mobile-container">
          <img src={hamburger} className={`menu ${hamburgerStyle}`} alt="logo" onClick={() => setExpanded(true)} />
        </div>
        <div className="left-container">
          <Link to={'/'} className="surround">
            <img
              src={logo}
              className="logo"
              alt="logo"
              id={!(useDark || scrollPosition > 720) ? 'shown-logo' : 'logo-basic'}
            />
            {!(useDark || scrollPosition > 720) && (
              <img src={logoHover} className="logo hover-logo" alt="logo" id="hover-logo" />
            )}
          </Link>
        </div>
        <div className="center-container">
          <div className="link-left" />
          <Link className={`link large-400 ${textStyle}`} to={'about-us'}>
            About Us
          </Link>
          <Link className={`link large-400 ${textStyle}`} to={'partners'}>
            Partners
          </Link>
          <Link className={`link large-400 ${textStyle}`} to={'watch'}>
            Watch
          </Link>
          <div className="link-right" />
        </div>
        <div className="right-container">
          <a href="https://www.tiktok.com/@badtraderapp" target="_blank" rel="noreferrer">
            <img src={tiktok} className={`social ${iconStyle}`} alt="logo" />
          </a>
          <a href="https://www.youtube.com/@badtraderapp/" target="_blank" rel="noreferrer">
            <img src={youtube} className={`social ${iconStyle}`} alt="logo" />
          </a>
          <a href="https://www.instagram.com/badtraderapp/" target="_blank" rel="noreferrer">
            <img src={insta} className={`social ${iconStyle}`} alt="logo" />
          </a>
          <a href="https://twitter.com/BadTraderApp" target="_blank" rel="noreferrer">
            <img src={twitter} className={`social ${iconStyle}`} alt="logo" />
          </a>
          <Link to="/#download">
            <Button label="Download" buttonStyle={buttonStyle} />
          </Link>
          {_.isEmpty(profile.id) ? (
            <Button label={'Login'} buttonStyle={buttonStyle} onClick={() => setLoginModalVisible(true)} />
          ) : (
            <Link to={'.'}>
              <Button label={'Logout'} buttonStyle={buttonStyle} onClick={logout} />
            </Link>
          )}
        </div>
      </div>
      <div className="header-placeholder" />
    </>
  )
}
