// import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Root from './routes/root'
import ErrorPage from './routes/error-page'
import Landing from './routes/landing'
import Contact from 'routes/contact'
import Partners from 'routes/partners'
import AboutUs from 'routes/about-us'
import Faq from 'routes/faq'
import PrivacyPolicy from 'routes/legal/privacy-policy'
import Watch from 'routes/watch'
import HostApplication from 'routes/host-application'
import Download from 'routes/download'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    // loader: rootLoader,
    children: [
      {
        path: '/',
        element: <Landing />
      },
      {
        path: '/contact',
        element: <Contact />
      },
      {
        path: '/about-us',
        element: <AboutUs />
      },
      {
        path: '/partners',
        element: <Partners />
      },
      {
        path: '/faq',
        element: <Faq />
      },
      {
        path: '/privacy-policy',
        element: <PrivacyPolicy />
      },
      {
        path: '/watch',
        element: <Watch />
      },
      {
        path: '/host-application',
        element: <HostApplication />
      },
      {
        path: '/uid/*',
        element: <Download />
      },
      {
        path: '/post/*',
        element: <Download />
      },
      {
        path: '/download',
        element: <Download />
      },
    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  <RouterProvider router={router} />
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
