import 'styles/landing.css'
import 'styles/text.css'
import chaseGus from 'assets/chaseGus.png'
import crew1 from 'assets/crew1.png'
import crew2 from 'assets/crew2.png'
import phone from 'assets/phone.png'
import logoGif from 'assets/logo.gif'
import promo from 'assets/promo.png'
import mail from 'assets/mail.svg'
import ufo from 'assets/ufo.gif'
import { Link } from 'react-router-dom'
import Button from 'components/button'
import Background from 'components/background'
import bg from 'assets/landingBackground.jpeg'
import ScrollToAnchor from 'components/anchor'

export default function Landing() {
  return (
    <>
      <ScrollToAnchor />
      <Background src={bg} tintColor={'#ff8f8f'} />
      <div className="header">
        <div className="h1 header-text">
          Mistakes suck less
          <br />
          with friends.
        </div>
        <img src={ufo} className="ufo" alt="chaseGus" />
      </div>
      <div className="content">
        <div className="content-container margin-content">
          <div className="h4 about">
            Looking for a place to experiment with risk and speculation? Us too. Bad Traders will be able to connect to
            their broker and tune in for daily livestreams where we argue about trading, sports, politics and other
            things that probably matter.
          </div>
          <div className="photo-container photo-container-small">
            <div className="h2 highlight-purple">Finance for %#$&ups.</div>
            <img src={chaseGus} className="chase-gus" alt="chaseGus" />
          </div>
        </div>
        <div className="content-container">
          <div className="photo-container">
            <img src={crew1} className="photo" alt="crew" />
          </div>
          <div className="small-content-container">
            <div className="h2 highlight-green">
              Crack
              <br />
              an
              <br />
              egg.
            </div>
            <div className="h3 info-text info-container-right">
              Come hang with the inmates for daily livestreams! We’ll discuss everything from trading to sports to your
              mom.
              <br />
              <Link to="/watch">
                <Button
                  buttonStyle="rounded-button-light rounded-button-label-light landing-button"
                  aria-label={'Download'}
                  label="Watch the Show"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="content-container reverse">
          <div className="small-content-container">
            <div className="h2 highlight-red">
              Meet <br />
              the <br />
              hosts.
            </div>
            <div className="h3 info-text info-container-left">
              Watch our completely unqualified hosts argue about U.S. monetary policy!
              <br />
              <Link to="/about-us">
                <Button
                  buttonStyle="rounded-button-light rounded-button-label-light landing-button"
                  label="Meet the Hosts"
                  aria-label={'Download'}
                />
              </Link>
            </div>
          </div>
          <div className="photo-container">
            <img src={crew2} className="photo" alt="crew2" />
          </div>
        </div>
        <div className="content-container">
          <div className="photo-container">
            <img src={phone} className="photo photo-higher" alt="phone" />
          </div>
          <div className="small-content-container">
            <div className="h2 highlight-purple purple-alignment">
              Light <br />
              that <br />
              keyboard <br />
              up.
            </div>
            <div className="h3 info-text info-container-right bottom-info-container">
              Think our hosts’ heads are up their @$$es? Put your keyboard where your mouth is and tell them in our live
              chat!
            </div>
          </div>
        </div>
        <div className="promo-background" id="download-1">
          <div className="promo-container">
            <div className="column-promo">
              <img src={logoGif} className="gif-logo-home" alt="logo" />
              <img src={promo} className="promo" alt="promo" />
            </div>
            <div className="promo-content-container">
              <div className="promo-header">Want to join our legion of Bad Traders?</div>
              <div className="promo-subline">Download the latest and greatest for your mobile device below.</div>
              <div className="button-container-promo">
                <a
                  href="https://play.google.com/store/apps/details?id=com.tastyworks.mobile.badtrader.android&hl=en_US&gl=US"
                  className="flex"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    buttonStyle="rounded-button-light rounded-button-label-light button-left button-long-small"
                    aria-label={'Android'}
                    label="Android"
                  />
                </a>
                <a
                  href="https://apps.apple.com/us/app/bad-trader/id1638952698"
                  className="flex"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    buttonStyle="rounded-button-light rounded-button-label-light button-long-small"
                    aria-label={'iOS'}
                    label="iOS"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="mailing-container">
          <div className="h2 mailing-header">
            Want to join our sexy
            <br /> mailing list?
          </div>
          <div className="mailing-bottom-container">
            <div className="mailing-form">
              <div className="mailing-text">
                As soon as we finish Last of Us, we'll send you app updates. Just drop your email here to keep in touch.
              </div>
              <iframe
                frameBorder="0"
                scrolling="no"
                title="form-email-list"
                className="mailing-form-iframe"
                src="https://cdn.forms-content-1.sg-form.com/c96d6330-517d-11ee-9f93-ea8b1437f8af"
              />
              {/* <div className="xx-small text-input input-label">
                Email
                <input
                  type="text"
                  className="input"
                  name="email"
                  value={email}
                  onChange={evt => setEmail(evt.target.value)}
                />
              </div>
              <Button
                buttonStyle="rounded-button-mail rounded-button-label-mail button-red"
                aria-label={'Download'}
                label="Submit"
                onClick={onSubmitEmail}
              /> */}
            </div>
            <div className="mail-photo-container">
              <img src={mail} className="mail" alt="chaseGus" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
