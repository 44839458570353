import 'styles/about-us.css'
import 'styles/text.css'
import arrows from 'assets/arrows.gif'
import microphone from 'assets/microphone.gif'
import eyes from 'assets/eyes.gif'
import errol from 'assets/errol.png'
import julia from 'assets/julia.png'
import thomas from 'assets/thomas.png'
import nwa from 'assets/nwa.png'
import luis from 'assets/luis.png'
import james from 'assets/james.png'
import ivan from 'assets/ivan.png'
import gus from 'assets/gus.png'
import chase from 'assets/chase.png'
import errolInactive from 'assets/errol-inactive.png'
import juliaInactive from 'assets/julia-inactive.png'
import thomasInactive from 'assets/thomas-inactive.png'
import nwaInactive from 'assets/nwa-inactive.png'
import luisInactive from 'assets/luis-inactive.png'
import jamesInactive from 'assets/james-inactive.png'
import ivanInactive from 'assets/ivan-inactive.png'
import gusInactive from 'assets/gus-inactive.png'
import chaseInactive from 'assets/chase-inactive.png'
import arrowLeft from 'assets/arrow-left.svg'
import location from 'assets/location.svg'
import trophy from 'assets/trophy.svg'
import chart from 'assets/chart.svg'
import document from 'assets/document.svg'
import education from 'assets/education.svg'
import sparkle from 'assets/sparkle.svg'
import Button from 'components/button'
import { useEffect, useRef, useState } from 'react'
import Contentstack from 'contentstack'
import _ from 'lodash'
import { Host } from 'lib/contentstack'
import { Link } from 'react-router-dom'
import bg from 'assets/hostsBackground.jpeg'
import Background from 'components/background'

const hostListOrdered = ['James', 'Thomas', 'Julia', 'Nwachukwu', 'Errol', 'Ivan', 'Gus', 'Chase', 'Luis']

export default function AboutUs() {
  const stack = Contentstack.Stack({
    api_key: 'blt7dfbfed3d53813b9',
    delivery_token: 'csb410fca7f78a5bb853c98799',
    environment: 'production'
  })
  const [currentIndex, setCurrentIndex] = useState(4)
  const [currentHost, setCurrentHost] = useState(null as Host)

  let hostsList = useRef(new Map<string, Host>())

  useEffect(() => {
    setCurrentHost(hostsList.current.get(hostListOrdered[currentIndex]))
  }, [hostsList, setCurrentHost, currentIndex])

  useEffect(() => {
    stack
      .ContentType('host')
      .Query()
      .toJSON()
      .find()
      .then(
        hosts => {
          hostsList.current = new Map(
            _.compact(hosts[0] as Host[]).map(host => [
              host.name.split(' ')[0],
              { firstName: host.name.split(' ')[0], ...host }
            ])
          ) as Map<string, Host>

          setCurrentHost(hostsList.current.get(hostListOrdered[currentIndex]))
        },
        _err => {
          console.error('content stack hosts error')
        }
      )
      .catch(_err => console.error('content stack hosts error'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const raise = () => {
    if (currentIndex < 8) {
      setCurrentIndex(currentIndex + 1)
    }
  }

  const lower = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
    }
  }

  return (
    <>
      <Background src={bg} tintColor={'#fdff8f'} />
      <div className="header-hosts">
        <div className="h1 header-hosts-text">
          Meet the
          <br />
          hosts.
        </div>
        <img src={microphone} className="microphone" alt="microphone" />
        <img src={arrows} className="arrows" alt="arrows" />
      </div>
      <div className="hosts-container">
        <div className="carousel-container">
          <div className="carousel-left" onClick={lower} />
          <div className="carousel-right" onClick={raise} />
          <img src={arrowLeft} className="arrow-left left-arrow" alt="arrow-left" />
          <img
            src={arrowLeft}
            className="arrow-left flipped"
            alt="arrow-right"
            onClick={() => setCurrentIndex(currentIndex + 1)}
          />
          <div className="slider-container">
            <div
              className="slider"
              style={{
                transform: `translateX(${
                  (currentIndex - 1) * -10 +
                  (currentIndex < 4 ? 2 + Math.abs(4 - currentIndex) : -Math.abs(4 - currentIndex))
                }%) translateX(0px)`
              }}
            >
              <div className={`carousel-item pos-${currentIndex > 4 ? 4 : Math.abs(currentIndex)}`}>
                <img src={currentIndex === 0 ? james : jamesInactive} className="headshot" alt="james" />
              </div>
              <div className={`carousel-item pos-${currentIndex > 5 ? 4 : Math.abs(1 - currentIndex)}`}>
                <img src={currentIndex === 1 ? thomas : thomasInactive} className="headshot" alt="thomas" />
              </div>
              <div className={`carousel-item pos-${currentIndex > 6 ? 4 : Math.abs(2 - currentIndex)}`}>
                <img src={currentIndex === 2 ? julia : juliaInactive} className="headshot" alt="julia" />
              </div>
              <div className={`carousel-item pos-${currentIndex > 7 ? 4 : Math.abs(3 - currentIndex)}`}>
                <img src={currentIndex === 3 ? nwa : nwaInactive} className="headshot" alt="nwa" />
              </div>
              <div className={`carousel-item pos-${Math.abs(4 - currentIndex)}`}>
                <img src={currentIndex === 4 ? errol : errolInactive} className="headshot" alt="errol" />
              </div>
              <div className={`carousel-item pos-${currentIndex < 1 ? 4 : Math.abs(5 - currentIndex)}`}>
                <img src={currentIndex === 5 ? ivan : ivanInactive} className="headshot" alt="ivan" />
              </div>
              <div className={`carousel-item pos-${currentIndex < 2 ? 4 : Math.abs(6 - currentIndex)}`}>
                <img src={currentIndex === 6 ? gus : gusInactive} className="headshot" alt="gus" />
              </div>
              <div className={`carousel-item pos-${currentIndex < 3 ? 4 : Math.abs(7 - currentIndex)}`}>
                <img src={currentIndex === 7 ? chase : chaseInactive} className="headshot" alt="chase" />
              </div>
              <div className={`carousel-item pos-${currentIndex < 4 ? 4 : Math.abs(8 - currentIndex)}`}>
                <img src={currentIndex === 8 ? luis : luisInactive} className="headshot" alt="luis" />
              </div>
            </div>
          </div>
        </div>
        <div className="details-container">
          <div className="details-left">
            <div
              className="h2 name"
              style={{ color: currentHost?.colors.neon, textShadow: `0px 0px 20px ${currentHost?.colors.glow}` }}
            >
              {currentHost?.firstName}.
            </div>
            <div className="section-container">
              <div className="large-600 section-label">
                <img src={location} className="section-icon" alt="errol" />
                Hometown
              </div>
              <div className="large-500 section-data" style={{ color: currentHost?.colors.neon }}>
                {currentHost?.hometown}
              </div>
            </div>
            <div className="section-container">
              <div className="large-600 section-label">
                <img src={trophy} className="section-icon" alt="errol" />
                Top Expertise
              </div>
              <div className="large-500 section-data" style={{ color: currentHost?.colors.neon }}>
                {currentHost?.top_expertises.replaceAll(',', ', ')}
              </div>
            </div>
            <div className="section-container">
              <div className="large-600 section-label">
                <img src={chart} className="section-icon" alt="errol" />
                Trading Style
              </div>
              <div className="large-500 section-data" style={{ color: currentHost?.colors.neon }}>
                {currentHost?.strategies.replaceAll(',', ', ')}
              </div>
            </div>
            <div className="section-container">
              <div className="large-600 section-label">
                <img src={document} className="section-icon" alt="errol" />
                Background
              </div>
              <div className="large-500 section-data" style={{ color: currentHost?.colors.neon }}>
                {currentHost?.background_experience}
              </div>
            </div>
            <div className="section-container">
              <div className="large-600 section-label">
                <img src={education} className="section-icon" alt="errol" />
                Education
              </div>
              <div className="large-500 section-data" style={{ color: currentHost?.colors.neon }}>
                {currentHost?.education}
              </div>
            </div>
            <div className="section-container">
              <div className="large-600 section-label">
                <img src={sparkle} className="section-icon" alt="errol" />
                Fun Facts
              </div>
              <div className="large-500 section-data" style={{ color: currentHost?.colors.neon }}>
                {currentHost?.personal_description}
              </div>
            </div>
          </div>
          <div className="details-right">
            <div className="h4 favorite-header">Favorite...</div>
            <div className="small-400 favorite-container favorite-data">
              <div className="small-600 favorite-label favorite-left">Food</div>
              {_.get(currentHost, 'favorites.food')}
            </div>
            <div className="small-400 favorite-container favorite-data">
              <div className="small-600 favorite-label favorite-left">Travel Destination</div>
              {_.get(currentHost, 'favorites.destination')}
            </div>
            <div className="small-400 favorite-container favorite-data">
              <div className="small-600 favorite-label favorite-left">Movie</div>
              {_.get(currentHost, 'favorites.movie')}
            </div>
            <div className="small-400 favorite-container favorite-data">
              <div className="small-600 favorite-label favorite-left">Music</div>
              {_.get(currentHost, 'favorites.music')}
            </div>
            <div className="small-400 favorite-container favorite-data">
              <div className="small-600 favorite-label favorite-left">Game</div>
              {_.get(currentHost, 'favorites.game')}
            </div>
            <div className="small-400 favorite-container favorite-data">
              <div className="small-600 favorite-label favorite-left">Animal</div>
              {_.get(currentHost, 'favorites.animal')}
            </div>
            <div className="small-400 favorite-container favorite-data">
              <div className="small-600 favorite-label favorite-left">Sport</div>
              {_.get(currentHost, 'favorites.sport')}
            </div>
            <div className="small-400 favorite-container favorite-data">
              <div className="small-600 favorite-label favorite-left">Ticker</div>
              {_.get(currentHost, 'favorites.ticker')}
            </div>
          </div>
        </div>
      </div>
      <div className="help-wanted-container">
        <div className="help-wanted-left">
          <div className="h2 help-wanted-header">Want to become a host?</div>
          <div>
            <div className="h5 help-wanted-subheader">Answer this questionnaire and then we’ll talk.</div>
            <Link to="/host-application">
              <Button
                buttonStyle="rounded-button-light rounded-button-label-light long-button button-host"
                aria-label="host-app"
                label="I want to be a Host"
              />
            </Link>
          </div>
        </div>
        <div className="help-wanted-right">
          <img src={eyes} className="eyes" alt="eyes" />
        </div>
      </div>
    </>
  )
}
