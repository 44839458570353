import 'styles/faq.css'
import 'styles/text.css'
import arrowDown from 'assets/arrow-down.gif'
import arrowLeft from 'assets/arrow-left.svg'
import confused from 'assets/confused.gif'
import BTABroker from 'assets/BTABroker.gif'
import Fees from 'assets/Fees.gif'
import SendMail from 'assets/SendMail.gif'
import StartingOnBT from 'assets/StartingOnBT.gif'
import WhatsTheDifference from 'assets/WhatsTheDifference.gif'
import WhatisBadTrader from 'assets/WhatisBadTrader.gif'
import WhatArePoints from 'assets/WhatArePoints.gif'
import { useState } from 'react'
import Button from 'components/button'
import Background from 'components/background'
import bg from 'assets/faq-background.jpeg'

export default function Faq() {
  return (
    <>
      <Background src={bg} tintColor={'#8ff2ff'} />
      <div className="header-faq">
        <div className="h1 header-text-faq">Some stuff we're frequently asked.</div>
        <div className="faq-description-container">
          <div className="h3 faq-description-content">How many cheeseburgers wide is the Earth?</div>
        </div>
        <img src={arrowDown} className="arrow-down" alt="arrows" />
      </div>
      <div className="content-faq">
        <div className="faq-container">
          <FAQRow
            title="What is Bad Trader"
            text="Bad Trader is an app that offers daily livestreams and chat, and will offer users the ability to connect
        to their brokerage account. In the future, users can also earn daily rewards, accumulate points, lose
        all your points on a bet that goes south, and experiment with speculation without risking anything but
        your time and dignity."
            src={WhatisBadTrader}
          />
          <FAQRow
            title="What are points?"
            text="Points are in-app currency you accumulate by watching streams, completing polls, and hanging with the
            inmates. They have no monetary value or cash equivalence, but with points, users can buy chat rewards,
            upgrade your profile or wager them straight to the moon."
            src={WhatArePoints}
          />
          <FAQRow
            title="How do I get started on Bad Trader?"
            text="To use the full extent of the Bad Trader app, you’ll need to either open a brokerage account or log into
            an existing account through one of our brokerage affiliates. Not ready to trade just yet? Lurkers
            welcome! Users with engagement-only accounts can still access all non-trading features on Bad Trader
            such as stream and chat."
            src={StartingOnBT}
          />
          <FAQRow
            title="Is Bad Trader a broker?"
            text="Bad Trader is not a broker-dealer. Bad Trader is an application that connects electronically to certain
            brokers through those brokers’ APIs. The Bad Trader app allows users to (i) send messages to those
            brokers that contain orders and (ii) view certain information about their brokerage accounts at those
            brokers. Bad Trader does not execute or get involved with your trades or your brokerage account. Check
            out the Trade page for more information on the specific brokers that users can connect to through the
            Bad Trader app."
            src={BTABroker}
          />
          <FAQRow
            title="What’s the difference between my broker and Bad Trader?"
            text="Bad Trader is not a broker-dealer. Bad Trader is an application that connects electronically to certain
            brokers through those brokers’ APIs. The Bad Trader app allows users to (i) send messages to those
            brokers that contain orders and (ii) view certain information about their brokerage accounts at those
            brokers. Bad Trader does not execute or get involved with your trades or your brokerage account. Check
            out the Trade page for more information on the specific brokers that users can connect to through the
            Bad Trader app."
            src={WhatsTheDifference}
          />
          <FAQRow
            title="Where do I send my support questions?"
            text="Because Bad Trader does not hold your account or execute your orders, our support team cannot answer any
            support questions pertaining to your broker, your orders, your brokerage account, or your ability to
            trade. We can answer questions pertaining to our platform (such as why chat isn’t working or why your
            points are not updating), but any questions pertaining to your brokerage account, orders, fills, or
            quotes should be directed to your broker."
            src={SendMail}
          />
          <FAQRow
            title="What kind of fees can I expect?"
            text="Trading fees and commissions are charged by the broker. Bad Trader is not a broker-dealer and does not
            charge any additional fees to its users. To learn more about the fees your broker charges, check out
            their applicable commissions and fees on their website."
            src={Fees}
          />
        </div>
        <div className="h4 faq-container-footer">
          <div className="faq-row remove-border">
            <div className="faq-header-container faq-header-container-confused">
              <img src={confused} className="confused" alt="confused" />
              Are you still absolutely confused as hell?
            </div>
          </div>
          <Button
            buttonStyle="rounded-button-light rounded-button-label-light contact-us"
            aria-label={'Download'}
            label="Contact Us"
          />
        </div>
      </div>
    </>
  )
}

function FAQRow({ title, text, src }) {
  const [expanded, setExpanded] = useState(false)

  return (
    <div className="faq-row">
      <div className="faq-header-container" onClick={() => setExpanded(!expanded)}>
        <div className={`h4 faq-header ${expanded && 'highlighted-header'}`}>{title}</div>
        <img src={arrowLeft} className={`arrow-right-faq mirror ${expanded && 'arrow-animate-faq'}`} alt="arrows" />
      </div>
      <div className={`faq-item ${expanded && 'faq-animate-open'}`}>
        <div className="large-600 faq-text">{text}</div>
        <div className="animation-container">
          <img src={src} className="faq-animation" alt="arrows" />
        </div>
      </div>
    </div>
  )
}
